<template>
  <AppLoading v-if="!user" fillSpace></AppLoading>
  <div
    v-else
    class="
      reset-password
      d-block
      ml-auto
      mr-auto
      p-3
      d-flex
      flex-column
      align-items-center
      justify-content-md-center
      h-100
    "
  >
    <img src="@/assets/logo_named.png" alt="Logo" width="140px" />
    <h2 class="mt-3">PASSWORD RESET</h2>
    <div class="p-3 w-100 d-flex bg-gray-light-4">
      <div class="d-flex align-items-center">
        <AppMediaViewer
          width="40px"
          aspectRatio="1/1"
          class="rounded-circle"
        ></AppMediaViewer>
      </div>
      <div class="ml-2">
        <p class="m-0 font-weight-5">
          {{ user | name }}
        </p>
        <p class="m-0 font-weight-5 text-gray">
          {{ user.email }}
        </p>
      </div>
    </div>
    <AppInputForm
      v-model="formValid"
      v-slot="{ validate }"
      class="mt-3 mb-5 w-100"
      @validate="resetPassword"
    >
      <!-- New Password -->
      <AppInput
        v-model="password.value"
        type="password"
        label="New Password"
        class="w-100"
        :validations="password.validations"
        :validate="validate"
      ></AppInput>
      <!-- Confirm New Password -->
      <AppInput
        v-model="password_confirm.value"
        type="password"
        label="Confirm New Password"
        class="mt-2 w-100"
        :validations="password_confirm.validations"
        :validate="validate"
      ></AppInput>
      <AppBtn class="mt-2 w-100" type="submit" :loading="loading">
        CONFIRM NEW PASSWORD
      </AppBtn>
    </AppInputForm>

    <!-- Add Success Modal -->
    <SuccessModal v-model="passwordResetModal" @confirm="$router.push('login')">
      <template v-slot:title> Go to Login </template>
      <template v-slot:description></template>
    </SuccessModal>
  </div>
</template>

<script>
import AppBtn from '../../shared/elements/AppBtn.vue';
import AppInput from '../../shared/elements/AppInput.vue';
import AppInputForm from '../../shared/elements/AppInputForm.vue';
import AppMediaViewer from '../../shared/elements/AppMediaViewer.vue';
import AppLoading from '../../shared/elements/AppLoading.vue';
import Users from '@/shared/api/Users';
import Authentication from '@/shared/api/Authentication';
import validation from '@/shared/utils/validation';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';

export default {
  name: 'ResetPassword',

  components: {
    AppInputForm,
    AppInput,
    AppMediaViewer,
    AppBtn,
    AppLoading,
    SuccessModal,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      formValid: false,
      loading: false,
      passwordResetModal: false,
      password: {
        value: '',
        validations: [validation.required()],
      },
      password_confirm: {
        value: '',
        validations: [
          validation.required(),
          validation.matchString('', 'Password field'),
        ],
      },
      user: null,
    };
  },

  watch: {
    password: {
      handler({ value }) {
        this.password_confirm.validations = [
          validation.required(),
          validation.matchString(value, 'Password field'),
        ];
      },
      deep: true,
    },
  },

  filters: {
    name(value) {
      if (value.information && value.information.first_name) {
        const info = value.information;
        return `${info.first_name} ${info.last_name}`;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    async fetchUserData() {
      const id = this.$route.query.user_id;
      if (!id) {
        return;
      }
      const response = await Users.getUser(id);
      this.user = response.data;
    },
    async resetPassword() {
      if (!this.formValid) {
        return;
      }

      this.loading = true;

      await Authentication.resetPassword({
        code: this.$route.query.code,
        email: this.user.email,
        password: this.password.value,
        password_confirmation: this.password_confirm.value,
      });

      this.loading = false;
      this.passwordResetModal = true;

      // this.$store.dispatch('api/setModal', {
      //   header: response.data,
      //   description: 'Click the "Go to Login" button to proceed',
      //   btnText: response.status === 200 ? 'Go to Login' : 'Close',
      //   onClick: () => {
      //     if (response.status === 200) {
      //       this.$router.push({ name: 'Login' });
      //     }
      //   },
      // });
    },
  },

  mounted() {
    this.fetchUserData();
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  max-width: 600px;
}
</style>
